import { useState } from 'react'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Anchor, Badge, createStyles, Divider, Group, Navbar, Text } from '@mantine/core'
import {
  IconChecklist,
  IconFileText,
  IconSettings,
  IconStack2,
  IconUsers,
  IconUmbrella,
  IconApps,
} from '@tabler/icons-react'

import { Link, useLocation } from '@redwoodjs/router'

import { capitalizeFirstLetter } from 'src/lib/formatters'

import fortueLogo from './../../../../public/fortue.png'

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef('icon')
  return {
    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: theme.spacing.md * 1.5,
      borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
    },

    logo: {
      height: '32px',
      display: 'inline-block',
    },

    logo_text: {
      fontWeight: 500,
      fontSize: '24px',
      color: theme.colors.gray[7],
      marginLeft: theme.spacing.xs,
      verticalAlign: 'top',
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,

        [`& .${icon}`]: {
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({
          variant: 'light',
          color: theme.primaryColor,
        }).background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
        [`& .${icon}`]: {
          color: theme.fn.variant({
            variant: 'light',
            color: theme.primaryColor,
          }).color,
        },
      },
    },
  }
})

function NavElement(item, cx, activeNavBar, setActiveNavBar, classes) {
  return (
    <Link
      key={item.label}
      to={item.link}
      style={{ textDecoration: 'none' }}
      onClick={(event) => {
        setActiveNavBar(item.label)
        event.preventDefault()
      }}
      className={cx(classes.link, {
        [classes.linkActive]: item.label === activeNavBar,
      })}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </Link>
  )
}

// TODO: use redwood router here
const upperNavbarOptions = [
  { link: '/lienwaivers', label: 'Lien Waivers', icon: IconChecklist },
  { link: '/insurances', label: 'Insurance', icon: IconUmbrella },
]

const middleNavbarOptions = [
  { link: '/projects', label: 'Projects', icon: IconStack2 },
  { link: '/vendors', label: 'Vendors', icon: IconUsers },
  { link: '/templates', label: 'Templates', icon: IconFileText },
]

const lowerNavbarOptions = [{ link: '/integrations', label: 'Integrations', icon: IconApps }]

const bottomNavbarOptions = [{ link: '/settings', label: 'Settings', icon: IconSettings }]

export function LeftNavbar() {
  const { classes, cx } = useStyles()
  const { pathname } = useLocation()
  const curModuleName = capitalizeFirstLetter(pathname.split('/')[1]) // Lienwaivers, Projects, Settings, etc
  const curNavBarTitle = curModuleName === 'Lienwaivers' ? 'Lien Waivers' : curModuleName

  const [activeNavBar, setActiveNavBar] = useState(curNavBarTitle)

  const upper = upperNavbarOptions.map((item) => NavElement(item, cx, activeNavBar, setActiveNavBar, classes))
  const middle = middleNavbarOptions.map((item) => NavElement(item, cx, activeNavBar, setActiveNavBar, classes))
  const lower = lowerNavbarOptions.map((item) => NavElement(item, cx, activeNavBar, setActiveNavBar, classes))
  const bottom = bottomNavbarOptions.map((item) => NavElement(item, cx, activeNavBar, setActiveNavBar, classes))

  const integrationsEnabled = useFeatureIsOn('agave-integrations')

  return (
    <Navbar width={{ base: 270 }} height="100%" p="md" hiddenBreakpoint="sm" className={classes.header}>
      <Group p="xs" mb="sm">
        <Anchor style={{ textDecoration: 'none' }} href="/lienwaivers">
          <img className={classes.logo} src={fortueLogo} alt="fortue-logo" />
          <Text span className={classes.logo_text}>
            FORTUE
          </Text>
        </Anchor>
        {process.env.NODE_ENV === 'development' && <Badge>Dev</Badge>}
      </Group>
      <Navbar.Section grow>
        {upper} <Divider my="sm" /> {middle} <Divider my="sm" hidden={!integrationsEnabled} />
        {integrationsEnabled && lower}
      </Navbar.Section>
      <Navbar.Section>{bottom}</Navbar.Section>
    </Navbar>
  )
}
