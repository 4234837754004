import { useEffect } from 'react'

import { AppShell } from '@mantine/core'

import { routes, navigate } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'

import { FortueHeader } from './FortueHeader/FortueHeader'
import { LeftNavbar } from './LeftNavbar/LeftNavbar'

type AppshellLayoutProps = {
  children?: React.ReactNode
}

const AppshellLayout = ({ children }: AppshellLayoutProps) => {
  const { client } = useAuth()

  useEffect(() => {
    const { data } = client.auth.onAuthStateChange(async (event) => {
      // logout if detected
      if (event == 'SIGNED_OUT') {
        navigate(routes.login())
      }
    })
    return () => {
      data.subscription.unsubscribe()
    }
  })

  return (
    <AppShell
      padding={0}
      layout="alt"
      navbar={<LeftNavbar />}
      header={<FortueHeader />}
      styles={(theme) => ({
        main: {
          backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
    >
      <Toaster />
      {children}
    </AppShell>
  )
}

export default AppshellLayout
