// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Private, Route, Router, Set } from '@redwoodjs/router'

import AppshellLayout from 'src/layouts/AppshellLayout'

import { useAuth } from './auth'

const Routes = () => {
  return (
    <Router useAuth={useAuth}>
      <Route path="/envelopes/pay-apps/{id:Int}/{token}" page={PayAppEnvelopePage} name="payAppEnvelope" />
      <Route path="/" page={LandingPage} name="dashboard" />
      <Route path="/forgot-password" page={AuthForgotPasswordPage} name="forgotPassword" />
      <Route path="/login" page={AuthLoginPage} name="login" />
      <Route path="/envelopes/lien-waivers/{uuid}/{token}" page={EnvelopeLienWaiversPage} name="waiverEnvelope" />
      <Private unauthenticated="login">
        <Set wrap={AppshellLayout}>
          <Route path="/reset-password" page={AuthResetPasswordPage} name="resetPassword" />
          <Route path="/lienwaivers" page={LienWaiversPage} name="lienwaivers" />
          <Route path="/projects" page={ProjectsPage} name="projects" />
          <Route path="/projects/{id:Int}" page={ProjectPage} name="project" />
          <Route path="/vendors" page={VendorsPage} name="vendors" />
          <Route path="/vendors/{id:Int}" page={VendorPage} name="vendor" />
          <Route path="/settings" page={SettingsPage} name="settings" />
          <Route path="/templates" page={TemplatesPage} name="templates" />
          <Route path="/insurances" page={InsurancesPage} name="insurances" />
          <Route path="/insurances/{id:Int}" page={InsurancePage} name="insurance" />
          <Route path="/integrations" page={IntegrationsPage} name="integrations" />
          <Route path="/contracts/{id:Int}" page={ContractPage} name="contract" />
          <Route path="/payapps" page={PayAppsPage} name="payapps" />
          <Route path="/payapps/{id:Int}" page={PayAppPage} name="payapp" />
          <Route notfound page={NotFoundPage} />
        </Set>
      </Private>
    </Router>
  )
}

export default Routes
