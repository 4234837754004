import { Button, Group, createStyles, Flex, Header, Text, Badge } from '@mantine/core'
import { IconLogout } from '@tabler/icons-react'

import { useAuth } from 'src/auth'

const useStyles = createStyles((theme, _params, _) => {
  return {
    exit: {
      marginLeft: 'auto',
      marginRight: theme.spacing.sm,
    },
  }
})

export function FortueHeader() {
  const { classes } = useStyles()
  const { currentUser, logOut } = useAuth()
  const domainName = currentUser?.domainName || 'logged out'
  const email = currentUser?.email || 'logged out'

  return (
    <Header height={50}>
      <Flex mih={50} justify="flex-end" align="center">
        <Group className={classes.exit}>
          <Text> {email} </Text>
          <Badge color="blue">{domainName}</Badge>
          {currentUser?.impersonate && <Badge color="indigo">impersonated</Badge>}
          <Button onClick={logOut} className={classes.exit}>
            <IconLogout />
            <Text>Logout</Text>
          </Button>
        </Group>
      </Flex>
    </Header>
  )
}
