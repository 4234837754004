import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { MantineProvider } from '@mantine/core'
import theme from 'config/mantine.config'

import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import { AuthProvider, useAuth } from './auth'

import './index.css'
import './scaffold.css'

const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
})

if (process.env.NODE_ENV !== 'development') {
  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    site: process.env.DATADOG_RUM_SITE,
    service: process.env.DATADOG_RUM_SERVICE_NAME,
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}

const App = () => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures()
  }, [])

  return (
    <FatalErrorBoundary page={FatalErrorPage}>
      <GrowthBookProvider growthbook={growthbook}>
        <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
          <AuthProvider>
            <MantineProvider theme={theme} withNormalizeCSS>
              <RedwoodApolloProvider useAuth={useAuth}>
                <Routes />
              </RedwoodApolloProvider>
            </MantineProvider>
          </AuthProvider>
        </RedwoodProvider>
      </GrowthBookProvider>
    </FatalErrorBoundary>
  )
}

export default App
