import humanize from 'humanize-string'

const MAX_STRING_LENGTH = 150

export const formatEnum = (values: string | string[] | null | undefined) => {
  let output = ''

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value))
    output = humanizedValues.join(', ')
  } else if (typeof values === 'string') {
    output = humanize(values)
  }

  return output
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const jsonTruncate = (obj: unknown) => {
  return truncate(JSON.stringify(obj, null, 2))
}

export const timeTag = (dateTime?: string) => {
  let output: string | JSX.Element = ''

  if (dateTime) {
    output = (
      <time dateTime={dateTime} title={dateTime}>
        {new Date(dateTime).toUTCString()}
      </time>
    )
  }

  return output
}

export const checkboxInputTag = (checked: boolean) => {
  return <input type="checkbox" checked={checked} disabled />
}

export const formatDollarAndCents = (money: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  })
  return formatter.format(money)
}

// function which converts microcents to currency string  (e.g. 1000000 -> $10.00)
// https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
export const formatMicrocents = (microcents: number) => {
  return formatDollarAndCents(microcents / 1000000)
}

export const formatDaysAge = (timestamp: string) => {
  const currentTime = new Date().getTime()
  const pastTime = new Date(timestamp).getTime()

  const days = Math.floor((currentTime - pastTime) / (1000 * 60 * 60 * 24))

  return `${days} days`
}

export const parseIdFromValue = (value: string) => {
  const id = Number(value.split(/:(.*)/s)[0])
  return id
}

export const parseNameFromValue = (value: string) => {
  if (!value) {
    return ''
  }
  // take only the first colon
  const { name } = JSON.parse(value.split(/:(.*)/s)[1])
  return name
}

export const parseProjectOwnerFromValue = (value: string) => {
  if (!value) {
    return ''
  }

  const { projectOwner } = JSON.parse(value.split(/:(.*)/s)[1])
  return projectOwner
}

export const parseProjectLocationFromValue = (value: string) => {
  if (!value) {
    return ''
  }

  const { streetAddress, city, state, zip } = JSON.parse(value.split(/:(.*)/s)[1])
  return `${streetAddress}, ${city}, ${state} ${zip}`
}

export const parseEmailFromValue = (value: string) => {
  if (!value) {
    return ''
  }

  const { email } = JSON.parse(value.split(/:(.*)/s)[1])
  return email
}

export const parseContactNameFromVendorValue = (value: string) => {
  if (!value) {
    return ''
  }

  const { contactName } = JSON.parse(value.split(/:(.*)/s)[1])
  return contactName
}

export const parseReferenceNumFromValue = (value: string) => {
  if (!value) {
    return ''
  }

  const { referenceNum } = JSON.parse(value.split(/:(.*)/s)[1])

  return referenceNum
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
